import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { LoaderService, CommonConstant } from './../../../shared';
import {UserRoleService} from '../../../shared/services/userRole.service';
import { CommonService } from '../../../shared/services/common.service';

@Component({
	selector: 'layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
	private currentPageTitle: string;
	private setLayout: boolean;
	public collapseSidebar: boolean;
	private pageInfo: any;
	private hasPermission: boolean;
	private isShowSideBar: boolean;
	private flowType: string;
	private flowTypeList: Array<object> = [];
	private commonConstant: object = CommonConstant;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private title: Title,
		private loader: LoaderService,
		private userRoleService: UserRoleService,
		private toastr: ToastrService,
		private commonService: CommonService
	) {
		this.isShowSideBar = true;
		this.hasPermission = false;
		this.collapseSidebar = false;
		this.setLayout = true;
		this.currentPageTitle = '';
		this.getRouteParams();
	}

	public ngOnInit(): void {
		this.loader.start();

		this.userRoleService.fetchPermission()
			.subscribe((response: Array<object>) => {
				if (response
					&& response.hasOwnProperty('permissions')
					&& response.hasOwnProperty('roles')
					&& response['permissions'].length) {
					this.hasPermission = true;
					this.userRoleService.setRolePermission(response);
				} else {
					this.handleError();
				}
				this.loader.stop();
			}, error => {
				this.handleError(error);
			});
	}

	public handleError = (error?: any): void => {
		this.loader.stop();
		this.hasPermission = true;
		if (error) {
			this.toastr.error('Permission API error! Please refresh page');
		} else {
			this.router.navigate(['/forbidden']);
		}
	};

	/**
	 *
	 * @description Method to get title and layout from Data sent when route changes.
	 * @var event : has Layout and title. Layout defines whether we need sidebar and navbar.
	 *  Title contains the title for page
	 *
	 **/
	private getRouteParams = (): void => {
		this.router.events
			.filter(event => event instanceof NavigationEnd)
			.map(() => this.activatedRoute)
			.map(route => {
				while (route.firstChild) { route = route.firstChild; }
				return route;
			})
			.filter(route => route.outlet === 'primary')
			.mergeMap(route => route.data)
			.subscribe((event) => {
				this.setLayout = event['layout'];
				this.currentPageTitle = event['title'];
				this.title.setTitle(`Delhivery | ${event['title']}`);
				this.pageInfo = event;
				const serviceType = this.commonService.selectedApp && this.commonService.selectedApp['service_type']
					? this.commonService.selectedApp['service_type'].toUpperCase()
					: '';
				this.flowTypeList = serviceType && serviceType !== ''
					? [...this.commonConstant[`${serviceType}_FLOW`]]
					: [];
				this.flowType = this.commonService.selectedApp['flow_type'];
				this.reloadSideBar();
			});
	};

	private reloadSideBar = (): void => {
		this.isShowSideBar = false;
		setTimeout(() => {
			this.isShowSideBar = true;
			this.collapseSidebar = false;
		}, 0);
	};

	private hasSelectApp = (): boolean => {
		if (['mapping update status', 'bulk upload status', 'select app', 'forbidden', 'error']
			.includes(this.currentPageTitle.toLowerCase())) {
			return false;
		}
		return true;
	};

	/**
	 * @description Method to handle logout, navigating user back to login page
	 **/
	private onLogout = (): Promise<boolean> => this.router.navigate(['/login']);

	/**
	 *	@description Method to handle toggle of sidebar on clicking on bars
	 **/
	private toggleSidebar = (): boolean => this.collapseSidebar = !this.collapseSidebar;

	private changeFlowType = (flowType: string): void => {
		const path = location.hash.split('/');
		const lastPath = path[path.length - 1];
		const business = this.commonService.selectedApp;
		const pagePath = `${business['product_type']}/${business['service_type']}/${flowType}/${lastPath}`;
		this.router.navigate(
			[pagePath], { replaceUrl: true }
		);
	}
}

import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import {ToastrService} from 'ngx-toastr';

import {LoaderService} from '../../../shared/components';
import {CommonService, GoogleAnalyticsService} from '../../../shared/services';
import {PinService} from '../../services/dpp.service';

@Component({
	selector: 'pincode-serviceable-bulk-upload',
	templateUrl: './pinServiceableBulkUpload.component.html',
})
export class PincodeBulkUploadComponent implements OnInit {
	private fileData: FileList;
	private sampleCSVLink: string;
	private csvColumns: Array<string>;
	private readonly keys: object = null;

	constructor(
		private dppService: PinService,
		private loader: LoaderService,
		private toaster: ToastrService,
		private modal: MatDialog,
		private commonService: CommonService,
		private gaService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.csvColumns = [];
		this.keys = this.commonService.checkServiceType();
	}

	public ngOnInit(): void {
		this.sampleCSVDownload();
	}

	private initiateUpload = (event): void => {
		this.gaService.trackEvent(
			'click',
			'input',
			'dpp csv upload click',
			0
		);

		if (event
			&& event.target.files
			&& event.target.files.length
		) {
			this.fileData = event.target.files;
		} else {
			this.fileData = null;
		}
	};

	private upload = (): void => {
		this.loader.start();

		this.gaService.trackEvent(
			'submit',
			'button',
			'bulk upload',
			0
		);

		if (this.fileData.length) {
			const formData: FormData = new FormData();
			formData.append('file', this.fileData[0], this.fileData[0]['name']);

			this.dppService
				.uploadServicebalePinCSV(formData)
				.subscribe(response => {
					this.loader.stop();

					this.toaster.success('success', response['detail'], {timeOut: 3000});
				}, error => {
					this.loader.stop();
					error['detail'] = error['detail'].hasOwnProperty('file')
						? error['detail']['file'].stringify()
						: error['detail'];
					this.toaster.error(error['detail'], 'Error', {timeOut: 2000});
				});
		}
	};

	/**
     * to create sample CSV and make downloadable link
     */
	private sampleCSVDownload = (): void => {
		this.gaService.trackEvent(
			'click',
			'button',
			'sample dpp csv download',
			0
		);

		let csv = 'data:text/csv;charset=utf-8,';

		this.csvColumns = ['pincode', 'is_enabled'];
		csv += (this.csvColumns);
		this.sampleCSVLink = encodeURI(csv);
	};
}

import { Component } from '@angular/core';

@Component({
	selector: 'error-page',
	templateUrl: './errorHandling.component.html'
})
export class ErrorHandlingComponent {
	private error: object = {
		'status': null,
		'title': null,
		'message': null
	};

	constructor () {
		this.handleError();
	}

	public handleError = (status?: string, title?: string, message?: any): void => {
		this.error = Object.assign({}, {status, title, message});
	}
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'limit'})
export class DataRowLimitPipe implements PipeTransform {
	public transform(data: Array<object>, start: number, length: number): Array<object> {
		const result: Array<object> = [];

		for (let i = start; i < (start + length); i++) {
			if (data[i]) {
				result.push(data[i]);
			}
		}

		return result;
	}
}

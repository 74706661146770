import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services';
import {
	ForbiddenComponent,
	LayoutComponent,
	ErrorHandlingComponent
} from './components';

const pagesRoutes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivateChild: [AuthGuardService],
		children: [
			{
				path: '',
				redirectTo: '/select-app',
				pathMatch: 'full'
			},
			{
				path: 'select-app',
				loadChildren: 'app/appSelector/appSelector.module#AppSelectorModule',
				pathMatch: 'full',
			},
			{
				path: ':product_type/:service_type/:flow_type/:country_code/dashboard',
				loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
				canLoad: [AuthGuardService]
			},
			{
				path: ':product_type/:service_type/:flow_type/:country_code/mapping',
				loadChildren: 'app/mapping/mapping.module#MappingModule',
				canLoad: [AuthGuardService]
			},
			{
				path: ':product_type/:service_type/:flow_type/:country_code/remap',
				loadChildren: 'app/remap/remap.module#RemapModule',
				canLoad: [AuthGuardService]
			},
			{
				path: ':product_type/:service_type/:flow_type/:country_code/facility',
				loadChildren: 'app/facility/facility.module#FacilityModule',
				canLoad: [AuthGuardService]
			},
			// {
			// 	path: ':product_type/:service_type/:flow_type/:country_code/dpp',
			// 	loadChildren: 'app/dpp/dpp.module#DppModule',
			// 	canLoad: [AuthGuardService]
			// },
			{
				path: ':product_type/:service_type/:flow_type/:country_code/simulation',
				loadChildren: 'app/simulation/simul.module#SimulModule',
				canLoad: [AuthGuardService]
			},
			{
				path: 'status',
				loadChildren: 'app/update-status/updateStatus.module#UpdateStatusModule',
				canLoad: [AuthGuardService]
			},
			{
				path: 'forbidden',
				component: ForbiddenComponent,
				canLoad: [AuthGuardService],
				data: {
					title: 'Forbidden'
				}
			},
			{
				path: 'error',
				component: ErrorHandlingComponent,
				canLoad: [AuthGuardService],
				data: {
					title: 'Error'
				}
			},
			{
				path: ':product_type/:service_type/:flow_type/:country_code/pin',
				loadChildren: 'app/pin-service/pin.module#PinModule',
				canLoad: [AuthGuardService]
			},
		]
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(pagesRoutes)
	],
	exports: [
		RouterModule
	]
})

export class CoreRoutesModule {}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {CommonService} from '../../../shared/services';
import { CoreConstant } from '../../constant';

@Component({
	selector: 'sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	private currentRoute: string;
	private routes: Array<object> = [];
	private sidebarConst: object = CoreConstant;

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private commonService: CommonService
	) {}

	public ngOnInit(): void {
		const routeParam = this.setParams();
		this.router.events.subscribe((data) => {
			this.currentRoute = data['url'];
		});

		this.routes = [
			{
				path: `${routeParam}/dashboard`,
				title: 'Dashboard',
				icon: 'fa-tachometer-alt',
				exactMatch: true,
				permission : this.sidebarConst['DASHBOARD_PERMISSION'],
				show: true
			},
			{
				path: `${routeParam}/mapping`,
				title: 'Mapping',
				icon: 'fa-map',
				exactMatch: false,
				permission: this.sidebarConst['MAPPING_PERMISSION'],
				show: true
			},
			{
				path: `${routeParam}/remap`,
				title: 'Remap',
				icon: 'fa-sitemap',
				exactMatch: true,
				permission: this.sidebarConst['REMAP_PERMISSION'],
				show: location.hash.split('/').includes('b2c') && this.isShow()
			},
			{
				path: `${routeParam}/facility`,
				title: 'Facility',
				icon: 'fa-flag',
				exactMatch: true,
				permission: this.sidebarConst['FACILITY_PERMISSION'],
				show: true
			},
			// {
			// 	path: `${routeParam}/dpp`,
			// 	title: 'DPP',
			// 	icon: 'fa-handshake',
			// 	exactMatch: true,
			// 	permission: this.sidebarConst['DPP_PERMISSION'],
			// 	show: location.hash.split('/').includes('b2c') && this.isShow()
			// },
			{
				path: `${routeParam}/pin`,
				title: 'Pin Serviceability',
				icon: 'fa-map-pin',
				exactMatch: true,
				permission: this.sidebarConst['PIN_SERVICEABILITY'],
				show: location.hash.split('/').includes('b2b') && this.isShow()
			},
			{
				path: `${routeParam}/simulation`,
				title: 'Simulation',
				icon: 'fa-circle',
				exactMatch: true,
				permission: this.sidebarConst['SIMULATION_PERMISSION'],
				show: location.hash.split('/').includes('b2c') && this.isShow()
			}
		];
	}

	private isShow = (): boolean => {
		const country = location.hash.split('/')[4];

		if (country === 'lk' || country === 'bd') {
			return false;
		}

		return true;
	};

	private setParams = (): string => {
		const params = this.commonService.selectedApp;

		if (params) {
			return `/${params['product_type']}/${params['service_type']}/${params['flow_type']}/${params['country_code']}`;
		}
	};
}

import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from 'dlv-ng-auth';
// import { MenuService } from 'dlv-ng-services-menu';

import { environment } from './../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {GoogleAnalyticsService} from './shared/services';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	public authStatus: Boolean = false;
	constructor(
		private authService: AuthService,
		// private menuService: MenuService,
		private router: Router,
		private toaster: ToastrService,
		private vcr: ViewContainerRef,
		private gaService: GoogleAnalyticsService
	) {}

	public ngOnInit(): void {
		this.authService.init({
			environment: environment.env,
			onUnAuthorized: () => {
				window.location.href = '#/forbidden';
			}
		});

		// this.menuService.init({
		// 	environment: environment.env,
		// 	showServices : false,
		// 	showWaybill : false,
		// 	showCenter : false,
		// });

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.gaService.trackPageView(event['urlAfterRedirects']);
			}
		});

	}
}

import {Injectable} from '@angular/core';
import {FacilityService} from '../../facility/services/facility.service';

@Injectable()
export class FacilityNameService {
	private facilityList: Array<object>;

	constructor(
		private facilityService: FacilityService
	) {
		this.storeFacilityList();
	}

	public readName = (facilityID: string): string => {
		return (
			facilityID
				? this.facilityList[facilityID]
					? this.facilityList[facilityID]['name']
					: 'n/a'
				: 'n/a'
		);
	};

	private storeFacilityList = (): void => {
		this.facilityService
			.getFacilities()
			.subscribe(facilities => {
				this.facilityList = facilities;
			});
	};
}

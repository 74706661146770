import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LoaderService {
	private loadEvt: EventEmitter<boolean> = new EventEmitter();

	public getLoadState = (): EventEmitter<boolean> => this.loadEvt;

	public start = (): void => this.loadEvt.emit(true);

	public stop = (): void => this.loadEvt.emit(false);
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})

export class LoginComponent {
	constructor(private router: Router) { }

	private onLogin = (status: object): Promise<boolean> => this.router.navigate(['dashboard'], { replaceUrl: true });
}

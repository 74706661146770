/////////////////////////////////////////////////////
// TO SET THE COMMON API URLS & QUERY PARAMS FOR   //
//  ALL SHARED SERVICES & CONTROLLERS BETWEEN      //
//   THE DIFFERENT BUSINESS TYPE & SERVICE TYPE    //
/////////////////////////////////////////////////////

import {Injectable} from '@angular/core';
import { CommonService } from './common.service';

@Injectable()
export class APIService {
	private flow = 'fwd';

	constructor(
		private commonService: CommonService
	) {}

	private getFlowType = (): string => `flow=${this.commonService.selectedApp['flow_type'] || 'fwd' }`;

	/**
     * To set business type
     * i.e. b2b, b2c, heavy
     * (key aliasing for backend support is product_type)
     * @param       path                url taken from the browser
     * @returns     {*}                 queryparam product_type
     */
	private setBusinessType = (path: string): string => {
		let businessType;
		switch (true) {
			case path.includes('b2b'): {
				businessType = 'b2b';
			}
				break;

			case path.includes('b2c'): {
				businessType = 'b2c';
			}
				break;

			case path.includes('heavy'): {
				businessType = 'heavy';
			}
				break;

			default:
				businessType = 'b2c';
		}

		return `product_type=${this.commonService.selectedApp['product_type'] || 'b2c'}`;
	};

	/**
     * To set service type
     * i.e. lastmile (lm), firstmile(fm)
     * @param       path            url taken from the browser
     * @returns     {*}             queryparam service_type
     */
	private setServiceType = function(path: string): string {
		let serviceType;
		const paths = this.commonService.selectedApp['service_type'] || 'lastmile';

		switch (true) {
			case paths === 'lastmile': {
				serviceType = 'lm';
			}
				break;

			case paths === 'firstmile': {
				serviceType = 'fm';
			}
				break;

			default:
				serviceType = 'lm';
		}

		return `service_type=${serviceType}`;
	};

	private setCountry = (path: string): string => {
		const pathArray = path.split('/');
		return `country_code=${pathArray[4]}`;
	};

	public getApiPath = (): object => {
		const
			path = document.location.hash,
			queryParams = `/?${this.setBusinessType(path)}&${this.setServiceType(path)}&${this.getFlowType()}&${this.setCountry(path)}`;

		return {
			BASE: '/core/api/v2',
			ENDPOINT: '',
			QUERY_PARAMS: queryParams,
			PERMISSIONS: '/applications'
		};
	}
}

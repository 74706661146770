import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutesModule } from './app.routes';

import { DlvNgWsModule } from 'dlv-ng-ws';
import { SharedModule } from './shared/';
import { CoreModule } from './core';
import { AppComponent } from './app.component';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		CoreModule.forRoot(),
		DlvNgWsModule.forRoot(),
		ToastrModule.forRoot(),
		SharedModule.forRoot(),
		AppRoutesModule
	],
	bootstrap: [AppComponent]
})

export class AppModule { }

import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { LoaderService } from './loader.service';
import {Subscription} from 'rxjs/Rx';

@Component({
	selector: 'loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
	private state: Subscription;
	private showLoader = false;

	constructor(private loaderService: LoaderService) {}

	public ngOnInit(): void {
		this.checkState();
	}

	private checkState = (): void => {
		this.state = this.loaderService
			.getLoadState()
			.subscribe((res) => {

				window.setTimeout(() => {
					this.showLoader = res;
				});
			});
	};

	public ngOnDestroy() {
		this.state.unsubscribe();
	}

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {catchError, map} from 'rxjs/operators';

import {DlvHttpService} from 'dlv-ng-auth';
import {environment} from '../../../environments/environment';
import {APIService} from './api.service';
import {AUTOCOMPLETE} from '../apiEndPoints.constants';

@Injectable()
export class AutoCompleteService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService
	) {}

	private setQueryParams = (query: string, entity: string, city_id?: number): string => {
		const queryString = `${this.apiService.getApiPath()['QUERY_PARAMS']}`;

		if (entity === 'facility') {
			return queryString.concat(`&fac_name=${encodeURIComponent(query)}`);
		} else {
			return queryString.concat(`&city_id=${city_id}&to_search=${encodeURIComponent(query)}`);
		}
	};

	/**
     * to fetch the autocomplete result
     * @param query                     search string
     * @param endPoint                  location path (where to use)
     * @returns {Observable<any>}
     */
	public getSearchResult = (query: string, entity: string, city_id?: any): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = this.setQueryParams(query, entity, city_id);
		const url = `${environment.BASE_URI}${endPointBase}${AUTOCOMPLETE}${queryParams}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};
}

import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { UserRoleService } from '../services/userRole.service';

@Directive({ selector: '[permissions]' })
export class PermissionDirective implements OnInit {
	@Input() private permissions: any;

	constructor(
		private elem: ElementRef,
		private userRolePerm: UserRoleService
	) {}

	public ngOnInit(): void {
		if (this.permissions && !Array.isArray(this.permissions)) {
			this.permissions = this.permissions.split(',');
		}

		if (!this.checkPermission(this.permissions)) {
			this.elem.nativeElement.remove();
		}
	}

	private checkPermission = (permission: Array<object>): boolean => {
		const userPermission = this.userRolePerm.getRolePermission()['permission'];
		for (let i = 0; i < userPermission.length; i++) {
			if (permission && permission.indexOf(userPermission[i]) > -1) {
				return true;
			}
		}
		return false;
	};
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {APIService} from './api.service';
import {environment} from '../../../environments/environment';
import {COUNTRY} from '../apiEndPoints.constants';
import {DlvHttpService} from 'dlv-ng-auth/shared/services/http.service';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class CountryService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService
	) {}

	public getCountryList = (): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const url = `${environment.BASE_URI}${endPointBase}${COUNTRY}/`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(err => err)
				)
		);
	};
}

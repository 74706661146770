import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

import { DashboardService } from '../../services';
import { LoaderService } from '../../../shared/components';
import { CommonService, GoogleAnalyticsService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'bulk-upload',
	templateUrl: './bulk-upload.component.html',
})
export class BulkUploadComponent implements OnInit {
	private fileData: FileList;
	private sampleCSVLink: string;
	private csvColumns: Array<string>;
	private readonly keys: object = null;
	private statusLink;

	constructor(
		private dashboardService: DashboardService,
		private loader: LoaderService,
		private toaster: ToastrService,
		private modal: MatDialog,
		private commonService: CommonService,
		private gaService: GoogleAnalyticsService,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.csvColumns = [];
		this.keys = this.commonService.checkServiceType();
	}

	public ngOnInit(): void {
		this.sampleCSVDownload();
	}

	private initiateUpload = (event): void => {
		this.gaService.trackEvent(
			'click',
			'input',
			'file upload click',
			0
		);

		if (event
			&& event.target.files
			&& event.target.files.length
		) {
			this.fileData = event.target.files;
		} else {
			this.fileData = null;
		}
	};

	private setStatusLink = (response: object, city?: number): void => {
		if (city) {
			this.statusLink = `${environment.APP_BASE}/#/status/city-bulk-upload/${response['request_id']}`;
		} else {
			this.statusLink = `${environment.APP_BASE}/#/status/bulk-upload/${response['request_id']}`;
		}
	};

	private upload = (): void => {
		this.loader.start();

		this.gaService.trackEvent(
			'submit',
			'button',
			'bulk upload',
			0
		);

		if (this.fileData.length) {
			const formData: FormData = new FormData();
			formData.append('file', this.fileData[0], this.fileData[0]['name']);

			this.dashboardService
				.uploadBulkData(formData, this.data)
				.subscribe(response => {
					this.setStatusLink(response, this.data);
					this.loader.stop();

					this.toaster.success(
						`<a style="color: #fff" target="_blank" href=${this.statusLink}>Click here to check</a>`,
						response['detail'],
						{ timeOut: 10000, enableHtml: true }
					);
				}, error => {
					this.loader.stop();
					this.toaster.error(error['detail'], 'Error', { timeOut: 2000 });
				});
		}
	};

	/**
     * to create sample CSV and make downloadable link
     */
	private sampleCSVDownload = (): void => {
		this.gaService.trackEvent(
			'click',
			'button',
			'sample csv download',
			0
		);

		let csv = 'data:text/csv;charset=utf-8,';
		this.csvColumns = ['rec_id', 'loc_pin', 'loc_name', 'city', 'existing_facility', 'exst_dis', 'recommended_facility', 'rec_dis', 'loc_load', 'ops_recommendation', 'ops_reason']
		csv += (this.csvColumns);
		this.sampleCSVLink = encodeURI(csv);
	};
}

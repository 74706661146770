export const
	CITY_STATS = '/cities_stats',
	CITY_JOB_STATS = '/city_job_stats',
	NEAREST_CITIES = '/nearest_cities',
	FACILITY = '/facility',
	FACILITY_SERVICE = '/fac_service',
	FACILITIES = '/facilities',
	MAPPINGS = '/mappings',
	FULL_MAPPING = '/full_mapping',
	LAST_JOBS = '/lastx_job',
	AUTOCOMPLETE = '/autocomplete',

	BULK_UPLOAD = '/upload_mapping',
	CITY_BULK_UPLOAD = '/city_upload_mapping',
	RUN_JOB = '/run_job',
	FACILITY_MAPPING = '/facility_mapping',
	MAPPING_UPDATE = '/mapping_update',
	MAP_LOCALITIES = '/map/localities',
	COUNTRY = '/country_details',
	DPP_PinCode = '/dpp_pincode',
	UPLOAD_DPP_PinCode = '/upload_dpp_pincode',
	PIN_SERVICEABLE = '/serviceable_pincodes',
	UPLOAD_SERVICEABLE_PIN = '/upload_serviceable_pincodes',
	SIMULATION = '/fac-update-simulation';

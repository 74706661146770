import {Injectable} from '@angular/core';

@Injectable()
export class GoogleAnalyticsService {
	public trackPageView = (page) => (<any>window).ga('send', 'pageview', page);

	public trackEvent = (
		eventAction: string,
		eventCategory?: string,
		eventLabel?: string,
		eventValue?: number
	): void => {
		(<any>window).ga(
			'send',
			'event',
			{
				eventAction,
				eventCategory,
				eventLabel,
				eventValue
			}
		);
	}
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CoreRoutesModule } from './core.routes';

import { DlvNgAuthModule } from 'dlv-ng-auth';
// import { DlvNgServicesMenuModule } from 'dlv-ng-services-menu';
import {
	LayoutComponent,
	SidebarComponent,
	ForbiddenComponent,
	LoginComponent,
	ErrorHandlingComponent
} from './components';
import { AuthGuardService } from './services/';
import { SharedModule } from '../shared';

@NgModule({
	declarations: [
		LayoutComponent,
		SidebarComponent,
		ForbiddenComponent,
		LoginComponent,
		ErrorHandlingComponent
	],
	imports: [
		CommonModule,
		DlvNgAuthModule,
		// DlvNgServicesMenuModule,
		FormsModule,
		CoreRoutesModule,
		SharedModule
	],
	providers: [
		Title
	]
})

export class CoreModule {
	public static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModule,
			providers: [
				AuthGuardService
			]
		};
	}
}

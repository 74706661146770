import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'search'})
export class StaticDataFilterPipe implements PipeTransform {
	public transform(data: Array<object>, query: string, key: string): Array<object> {
		let queryString;
		queryString = query ? query.toLowerCase() : '';

		return data.filter((entry) => (entry[key]).toLowerCase().includes(queryString));
	}
}

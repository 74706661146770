import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';

@Component({
	selector: 'forbidden-page',
	templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent implements OnInit {
	/**
     *
     * @constructor method
     *
     */
	constructor() {}

	public ngOnInit(): void {}
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {BULK_UPLOAD, CITY_BULK_UPLOAD, FULL_MAPPING} from '../../shared/apiEndPoints.constants';
import { APIService, CommonService} from '../../shared/services';
import {DlvHttpService} from 'dlv-ng-auth';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class DashboardService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService,
		private commonService: CommonService
	) {}

	private setUploadEndPoint = (city?: number): string => {
		if (city) {
			return CITY_BULK_UPLOAD;
		}
		return BULK_UPLOAD;
	};

	public uploadBulkData = (formData: FormData, city_id?: number): Observable<any> => {
		const
			endPointBase = this.apiService.getApiPath()['BASE'],
			queryParams = this.apiService.getApiPath()['QUERY_PARAMS'],
			city = city_id ? `&city_id=${city_id}` : null,
			additionalParams = city ? city : '',
			url = `${environment.BASE_URI}${endPointBase}${this.setUploadEndPoint(city_id)}${queryParams}${additionalParams}`;

		const requestOptions = new HttpHeaders();

		return (
			this.http
				.post(url, formData, {withCredentials: true, ...requestOptions})
		);
	};

	public getMapping = (cityId?: number, facilityId?: string, type?: string): Observable<any> => {
		let url = '';
		const
			endPointBase = this.apiService.getApiPath()['BASE'],
			queryParams = this.apiService.getApiPath()['QUERY_PARAMS'],
			cityIdParam = cityId ? `&city_id=${cityId}` : '',
			facilityIdParam = facilityId ? `&facility_id=${facilityId}` : null,
			mappingTypeParam = type ? `&status=${type}` : null,
			userZone = this.commonService.getUserTimeZone(),
			optionalParams = (() => {
				if (type && facilityId) {
					return `${cityIdParam}${facilityIdParam}${mappingTypeParam}`;
				} else if (type) {
					return `${cityIdParam}${mappingTypeParam}`;
				} else {
					return cityIdParam;
				}
			})();

		if (userZone){
			url = `${environment.BASE_URI}${endPointBase}${FULL_MAPPING}${queryParams}${optionalParams}&user_tz=${userZone}`;
		} else {
			url = `${environment.BASE_URI}${endPointBase}${FULL_MAPPING}${queryParams}${optionalParams}`;
		}

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};
}

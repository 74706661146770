import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {APIService} from '../../shared/services';
import {environment} from '../../../environments/environment';
import {CITY_JOB_STATS, LAST_JOBS, RUN_JOB} from '../../shared/apiEndPoints.constants';
import {DlvHttpService} from 'dlv-ng-auth';
import {Observable} from 'rxjs/Rx';
import { throwError } from "rxjs";


@Injectable()
export class RemapService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService
	) {}

	public getJobHistory = (): Observable<object> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = this.apiService.getApiPath()['QUERY_PARAMS'];
		const url = `${environment.BASE_URI}${endPointBase}${LAST_JOBS}${queryParams}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response['result']),
					catchError(error => error)
				)
		);
	};

	public postJobRequest = (params: object): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = this.apiService.getApiPath()['QUERY_PARAMS'];
		const url = `${environment.BASE_URI}${endPointBase}${RUN_JOB}${queryParams}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'post', url, body: {...params}})
				.pipe(
					map(response => response),
					catchError(this.handleError)
				)
		);
	};

	handleError(error) {
		let errorMessage = "";
	
		if (
		  error.error instanceof ErrorEvent &&
		  (error.error.err_message || error.error.message)
		) {
		  // client-side error
		  errorMessage =
			(error && error.error) || error.error.message
			  ? error["error"]
			  : error;
		} else if (error.error) {
		  // server-side error
		  errorMessage = error.error;
		} else if (error.error.error["message"]) {
		  errorMessage = error.error;
		} else if (error) {
		  errorMessage = error.error;
		}
		if (!errorMessage && error) {
		  errorMessage = `${error}`;
		}
		return throwError(errorMessage);
	  }
	  
	/**
	 * To get job status of the selected city
	 * @param city    object        passed city data
	 */
	public getJobStatus = (cityId: number): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = this.apiService.getApiPath()['QUERY_PARAMS'];
		const url = `${environment.BASE_URI}${endPointBase}${CITY_JOB_STATS}${queryParams}&city_id=${cityId}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};
}

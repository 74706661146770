import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';
import {DlvHttpService} from 'dlv-ng-auth';

import {APIService} from '../../shared/services';
import {environment} from '../../../environments/environment';
import {DPP_PinCode, UPLOAD_DPP_PinCode} from '../../shared/apiEndPoints.constants';

@Injectable()
export class DppService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService
	) {}

	public getDPPList = (): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = `${this.apiService.getApiPath()['QUERY_PARAMS']}`;
		const url = `${environment.BASE_URI}${endPointBase}${DPP_PinCode}${queryParams}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};

	public uploadDPPCSV = (formData: FormData): Observable<any> => {
		const
			endPointBase = this.apiService.getApiPath()['BASE'],
			queryParams = this.apiService.getApiPath()['QUERY_PARAMS'],
			url = `${environment.BASE_URI}${endPointBase}${UPLOAD_DPP_PinCode}${queryParams}`;

		const requestOptions = new HttpHeaders();

		return (
			this.http
				.post(url, formData, {withCredentials: true, ...requestOptions})
		);
	};
}

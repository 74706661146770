import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';
import {DlvHttpService} from 'dlv-ng-auth';

import {APIService} from '../../shared/services';
import {environment} from '../../../environments/environment';
import {FACILITY, FACILITY_SERVICE, NEAREST_CITIES} from '../../shared/apiEndPoints.constants';

@Injectable()
export class FacilityService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService
	) {}

	public getFacilities = (facility_id?: string): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = `${this.apiService.getApiPath()['QUERY_PARAMS']}`;

		const url = facility_id
			? `${environment.BASE_URI}${endPointBase}${FACILITY}${facility_id ? `/${facility_id}` : ''}${queryParams}`
			: `${environment.BASE_URI}${endPointBase}${FACILITY_SERVICE}${queryParams}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};

	public getNearestCitiesOfFacility = (params: object): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = this.apiService.getApiPath()['QUERY_PARAMS'];
		const url = `${environment.BASE_URI}${endPointBase}${NEAREST_CITIES}${queryParams}&fac_code=${params['id']}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};
}

import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';

import {APIService} from './api.service';
import {AuthService} from 'dlv-ng-auth';
import {DlvHttpService} from 'dlv-ng-auth/shared/services/http.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Rx';
import { HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable()
export class UserRoleService {
	private userRole: Array<object> = [];
	private userPermission: Array<object> = [];

	constructor(
		private dlvHttp: DlvHttpService,
		private apiService: APIService,
		private authService: AuthService
	) {}

	public fetchPermission = (): Observable<object> => {
		const
			BASE = environment.UMS_BASE_URI,
			PERMISSION_PATH = this.apiService.getApiPath()['PERMISSIONS'],
			APP_ID = environment.APP_ID,
			data = this.authService.getUserProfile({server: false}),
			umsId = data !== undefined && data.sub !== undefined ? data.sub : '',
			url = `${BASE}${PERMISSION_PATH}/${APP_ID}/${umsId}/permissions/`;
			
		const headers = new HttpHeaders({
			'x-app-id' : `${environment.APP_ID}`
		})

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url, headers})
				.pipe(
					map(this.dlvHttp.responseData),
					catchError(this.dlvHttp.handleError)
				)
		);
	};

	public setRolePermission = (rolePerm: Array<object>): void => {
		this.userRole = this.allRoles(rolePerm);
		this.userPermission = this.allPermission(rolePerm);
	};

	private allRoles = (resp: object): Array<object> => {
		const roles = [];
		if (resp && resp.hasOwnProperty('roles') && resp['roles'].length) {
			const role = resp['roles'];
			for (const value of role) {
				roles.push(value.name);
			}
		}
		return roles;
	};

	private allPermission = (resp: object): Array<object> => {
		const permission = [];
		if (resp && resp.hasOwnProperty('permissions') && resp['permissions'].length) {
			const perm = resp['permissions'];
			for (const value of perm) {
				permission.push(value.name);
			}
		}
		return permission;
	};

	public getRolePermission = (): object => {
		return (
			{
				role: this.userRole,
				permission: this.userPermission
			}
		);
	};

	public checkPagePermission = (perm: any): boolean => {
		let permission;
		if (Array.isArray(perm)) {
			permission = perm;
		} else {
			permission = perm ? perm.split(',') : [];
		}

		if (!this.checkPermission(permission)) {
			return false;
		} else {
			return true;
		}
	};

	private checkPermission = (permission: string): boolean => {
		const userPermission = this.getRolePermission()['permission'];
		for (const value of userPermission) {
			if (permission.indexOf(value) > -1) {
				return true;
			}
		}
		return false;
	};

	public checkSpecificPermission = (permission: string): boolean => {
		const userPermission = this.getRolePermission()['permission'];
		for (const value of userPermission) {
			if (permission.indexOf(value) > -1) {
				return true;
			}
		}
		return false;
	};
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForbiddenComponent, LoginComponent } from './core/components';

import { AuthGuardService } from './core/';

const appRoutes: Routes = [
	{
		path: '',
		redirectTo: '/dashboard',
		pathMatch: 'full'
	},
	{
		path: '',
		loadChildren: 'app/core/core.module#CoreModule',
		canActivate: [AuthGuardService]
	},
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [AuthGuardService]
	},

	{ path: '**', pathMatch: 'full', redirectTo: '/dashboard' }
];

@NgModule(
	{
		imports: [
			RouterModule.forRoot(appRoutes, {'useHash': true})
		],
		exports: [
			RouterModule
		]
	}
)
export class AppRoutesModule {}

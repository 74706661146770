import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
	public selectedApp: object = {};

	public checkServiceType = (): object => {
		const hash = document.location.hash;
		let keys;

		if (hash.includes('lastmile')) {
			keys = {
				title: 'locality',
				pTitle: 'Localities',
				name: 'loc_name',
				id: 'loc_pin',
				key: 'loc_name_pin', // autocomplete case
				key_id: 'locality_id',
				key_ids: 'locality_ids', // to fetch mapping
				id_title: 'PIN Code',
				load: 'loc_load'
			};
		} else if (hash.includes('firstmile')) {
			keys = {
				title: 'client warehouse',
				pTitle: 'Client warehouses',
				name: 'name',
				id: 'cwh_id',
				key: 'cwh_name', // autocomplete case
				key_id: 'cwh_id',
				key_ids: 'cwh_ids', // to fetch mapping
				id_title: 'CWH ID',
				load: 'cwh_load'
			};
		}

		return keys;
	};

	public getUserTimeZone = (): string => {
		let timeZone: string;
		if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
			timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		} else {
			timeZone = '';
		}
		return timeZone
	}
}

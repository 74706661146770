// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	env: 'dev',
	APP_BASE: 'https://stg-mapper.delhivery.com',
	// BASE_URI: 'https://api-dev-mapper.delhivery.com',
	BASE_URI: 'https://api-dev-mapper.pntrzz.com',
	UMS_BASE_URI: 'https://api-stage-ums.delhivery.com/v2',
	APP_ID: 103,
	MAPS_KEY: '1ghv58m8rzbuv3rkmz1rdicev5awoafo',
	GA_KEY: 'UA-130712441-2',
	RETURN_MAPPER : 'https://polymapper-dashboard-dev.delhivery.com/'
};


export const CoreConstant: object = {
	'DASHBOARD_PERMISSION': ['can_get_city_stats'],
	'MAPPING_PERMISSION': ['can_get_city_stats'],

	'REMAP_PERMISSION': [
		'BtoC_lm_fwd_can_run_city_job',
		'BtoC_lm_ret_can_run_city_job',
		'BtoC_lm_rvp_can_run_city_job',
		'BtoC_fm_fwd_can_run_city_job',
		'BtoC_fm_ret_can_run_city_job',
		'BtoC_fm_rvp_can_run_city_job',
		'BtoB_fm_fwd_can_run_city_job',
		'BtoB_fm_ret_can_run_city_job',
		'BtoB_fm_rvp_can_run_city_job',
		'BtoB_lm_fwd_can_run_city_job',
		'BtoB_lm_ret_can_run_city_job',
		'BtoB_lm_rvp_can_run_city_job',
		'BtoC_lm_fwd_can_run_facility_job',
		'BtoC_lm_ret_can_run_facility_job',
		'BtoC_lm_rvp_can_run_facility_job',
		'BtoC_fm_fwd_can_run_facility_job',
		'BtoC_fm_ret_can_run_facility_job',
		'BtoC_fm_rvp_can_run_facility_job',
		'BtoB_fm_fwd_can_run_facility_job',
		'BtoB_fm_ret_can_run_facility_job',
		'BtoB_fm_rvp_can_run_facility_job',
		'BtoB_lm_fwd_can_run_facility_job',
		'BtoB_lm_ret_can_run_facility_job',
		'BtoB_lm_rvp_can_run_facility_job'
	],

	'FACILITY_PERMISSION': [
		'BtoB_fm_fwd_can_update_facility',
		'BtoB_fm_ret_can_update_facility',
		'BtoB_fm_rvp_can_update_facility',
		'BtoB_lm_fwd_can_update_facility',
		'BtoB_lm_ret_can_update_facility',
		'BtoB_lm_rvp_can_update_facility',
		'BtoC_fm_fwd_can_update_facility',
		'BtoC_fm_ret_can_update_facility',
		'BtoC_fm_rvp_can_update_facility',
		'BtoC_lm_fwd_can_update_facility',
		'BtoC_lm_ret_can_update_facility',
		'BtoC_lm_rvp_can_update_facility'
	],

	'DPP_PERMISSION': [
		'can_get_dpp_pincodes',
		'can_upload_dpp_pincodes'
	],

	'PIN_SERVICEABILITY':[
		'can_get_pincodes_serviceability',
		'can_get_serviceable_pincodes',
		'can_upload_serviceable_pincodes'
	],

	'SIMULATION_PERMISSION':[
		'BtoC_lm_fwd_can_simulate_fac_update'
	]
};


import {
	NgModule,
	ModuleWithProviders
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
	AutoCompleteComponent,
	LoaderComponent,
	LoaderService,
	MapComponent,
	PageForbidden,
} from './components/';

import {
	APIService,
	CityStatsService,
	CommonService,
	CountryService,
	GoogleAnalyticsService
} from './services';
import {AutoCompleteService} from './services/autocomplete.service';
import {DlvNgWsModule} from 'dlv-ng-ws';
import {DataRowLimitPipe, DomSanitizerPipe, StaticDataFilterPipe, StaticNumDataFilterPipe} from './pipes';
import {RemapComponent} from '../remap/components/remap.component';
import {RemapService} from '../remap/services/remap.service';
import {FacilityService} from '../facility/services/facility.service';
import {UserRoleService} from './services/userRole.service';
import { PermissionDirective } from './directive';
import {BulkUploadComponent} from '../dashboard/components/bulk-upload/bulk-upload.component';
import {DLVMaterialModule} from 'dlv-material';
import {DppBulkUploadComponent} from '../dpp/components/dpp-bulk-upload/dppBulkUpload.component';
import { PincodeBulkUploadComponent } from './../pin-service/components/pinServecable-bulk-upload/pinServiceableBulkUpload.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		DlvNgWsModule,
		DLVMaterialModule
	],
	declarations: [
		LoaderComponent,
		MapComponent,
		AutoCompleteComponent,
		StaticDataFilterPipe,
		DomSanitizerPipe,
		DataRowLimitPipe,
		StaticNumDataFilterPipe,
		PermissionDirective,
		PageForbidden,
		BulkUploadComponent,
		DppBulkUploadComponent,
		PincodeBulkUploadComponent
	],
	exports: [
		LoaderComponent,
		MapComponent,
		AutoCompleteComponent,
		StaticDataFilterPipe,
		DomSanitizerPipe,
		DataRowLimitPipe,
		StaticNumDataFilterPipe,
		DlvNgWsModule,
		DLVMaterialModule,
		FormsModule,
		PermissionDirective,
		PageForbidden
	],
	providers: [
		RemapComponent,
		RemapService,
		FacilityService,
		MapComponent
	]
})

export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [
				LoaderService,
				APIService,
				CityStatsService,
				CommonService,
				GoogleAnalyticsService,
				AutoCompleteService,
				UserRoleService,
				CountryService
			]
		};
	}
}

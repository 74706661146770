import {Component, Input} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';

import {environment} from '../../../../environments/environment';
import {MARKER, MARKER_COLOR} from './map.constants';
import {CommonService} from '../../services';
import {Subject} from 'rxjs/Subject';
import {FacilityNameService} from '../../services/facilityName.service';
import {isNullOrUndefined} from 'util';

@Component({
	selector: 'map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent {
	private map: L.Map;
	private zoomLevel: number;
	private markerRenderer: L.Canvas = L.canvas({padding: 0.1});
	private MARKER_COLOR: object = MARKER_COLOR;
	private MARKER: object = MARKER;
	private pinMarkers: L.FeatureGroup = L.featureGroup();
	public circleMarkers: Array<any> = [];
	private circleMarkerCluster: L.MarkerClusterGroup = L.markerClusterGroup({
		chunkedLoading: true
	});
	private larekha: L.FeatureGroup = L.featureGroup();
	private polygons: L.FeatureGroup = L.featureGroup();
	private showLegend: boolean;
	public polygonControl: object = {};
	private controlLayer;
	private readonly keys: object = null;
	public filterCallback: Subject<any> = new Subject<any>();

	@Input() private show: number;
	@Input() private mapPermission: boolean;

	constructor(
		private commonService: CommonService,
		private facilityNameService: FacilityNameService
	) {
		this.zoomLevel = 5;
		this.keys = this.commonService.checkServiceType();
	}


	public initializeMap = (): void => {
		this.map = L.map(
			'map',
			{
				center: this.setCenter(),
				zoom: this.zoomLevel,
				minZoom: 5,
				maxZoom: 18,
				zoomSnap: 0.5,
				zoomAnimation: true,
				zoomControl: true,
				markerZoomAnimation: true,
				scrollWheelZoom: true,
				preferCanvas: true,
				fadeAnimation: true,
				layers: [this.addTileLayer()],
			}
		);
		this.invalidateMapSize();

		this.map.zoomControl.setPosition('topright');
	};

	public invalidateMapSize = (): number => setTimeout(() => this.map.invalidateSize());

	private setCenter = (): L.LatLng => {
		const country = location.hash.split('/')[4];

		switch (country) {
			case 'in':
				this.zoomLevel = 5;
				return L.latLng(23, 81);
			case 'lk':
				this.zoomLevel = 8;
				return L.latLng(8, 80.5);
			case 'bd':
				this.zoomLevel = 7;
				return L.latLng(24, 90);
			default:
				return L.latLng(23, 81);
		}
	};

	/**
     * To clear all the existing layers before rendering the new data
     */
	public clearMapLayers = {
		pinMarkers: () => this.pinMarkers.clearLayers(),
		clusterMarker : () =>{
			this.circleMarkerCluster.clearLayers();
		},
		circleMarkers: () => {
			this.circleMarkers = [];
			this.circleMarkerCluster.clearLayers();
		},
		polygons: () => this.polygons.clearLayers(),
		larekhas: () => this.larekha.clearLayers(),
		all: () => {
			this.clearMapLayers.pinMarkers();
			this.clearMapLayers.circleMarkers();
			this.polygons.clearLayers();
			this.larekha.clearLayers();
		}
	};

	private addTileLayer = (): L.Layer => {

		const mmiUrl = `https://{s}.mapmyindia.com/advancedmaps/v1/${environment.MAPS_KEY}/still_map/{z}/{x}/{y}.png`;
		const googleUrl = 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}';
		const url = ['mt0', 'mt1', 'mt2', 'mt3'];
		const tiles = L.tileLayer(
			googleUrl,
			{
				minZoom: 5,
				maxZoom: 24,
				opacity: 0.9,
				subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
				attribution: 'Map Data',
			});

		const handleTileError = (evt: any) => {

			if (evt.tile._hasError) return;

			let si = Math.floor((Math.random() * 3));
			let tileSrc = mmiUrl.replace(/{s}/g, url[si]);
			tileSrc = tileSrc.replace(/{x}/g, evt.coords.x);
			tileSrc = tileSrc.replace(/{y}/g, evt.coords.y);
			tileSrc = tileSrc.replace(/{z}/g, evt.coords.z);
			evt.tile._hasError = true;
			evt.tile.src = tileSrc;
		};


		tiles.on('tileerror', handleTileError);


		return tiles;
	}

	public addMarker = (params: any, type: string, shape: string): void => shape === 'circle'
		? this.addCircleMarker(params, type)
		: this.addPinMarker(params, type);

	private addPinMarker = (params: any, type: string): void => {
		const pinMarker = L.marker(
			[params['lat'], params['lng']],
			{
				icon: this.createPinMarker(type),
				alt: 'icon'
			}
		);

		if (type !== 'nearby_facility') {
			pinMarker.addEventListener('click', (event) => {
				this.filterCallback.next(params);
			});
		}
		pinMarker.bindTooltip(this.createPinMarkerContent(params, type), {direction: 'top'});
		pinMarker.bindPopup(this.createPinMarkerContent(params, type));

		this.pinMarkers.addLayer(pinMarker);
	};

	private createPinMarker = (type: string): L.Icon<L.IconOptions> => {
		return L.icon({
			iconUrl: this.setMarkerPin(type),
			iconSize: [32, 32]
		});
	};

	private setMarkerPin = (type: string): string => {
		switch (type) {
			case 'facility': return MARKER['red'];
			case 'nearby_facility': return MARKER['purple'];
			case 'selected_facility': return MARKER['yellow'];
			case 'verified': return MARKER['green'];
			case 'no_recommendation': return MARKER['blue'];
			default: return MARKER['black'];
		}
	};

	private createPinMarkerContent = (params: object, type: string): string => {
		if (type === 'nearby_facility') {
			return (
				`<div><strong>` + params['name'] + ` (` + params['state'] + `)</strong></div>`
			);
		} else {
			return (
				`<div class="text-capitalize">
                    <div><strong>${params['name']} (${params['state']}</strong></div>
                    ${this.handleLoadRendering(params['exst_load'], 'Current Load')}
                    ${this.handleLoadRendering(params['rec_load'], 'Recomm. Load')}
                </div>`
			);
		}
	};

	private addCircleMarker = (params: object, type: string): void => {
		if(params['lat'] !==0 || params['lng']!==0){

			const circleMarker = L.circleMarker(
				[params['lat'], params['lng']],
				{
					radius: 4,
					stroke: true,
					weight: 0.5,
					color: '#000',
					fill: true,
					fillColor: this.setMarkerColor(type),
					lineCap: 'round',
					fillOpacity: 1,
					renderer: this.markerRenderer
				}
			);

			circleMarker.bindTooltip(this.createCircleMarkerContent(params), {direction: 'top'});
			circleMarker.bindPopup(this.createCircleMarkerContent(params));

			this.circleMarkers.push(circleMarker);
		}
	};

	public clusterMarkers = (shape: string,hasZoom?:boolean): void => {
		if (shape === 'circle' && this.circleMarkers && this.circleMarkers.length) {
			this.circleMarkerCluster.addLayers(this.circleMarkers);
			if(!hasZoom){
				this.fitDataToMapBoundaries(this.circleMarkerCluster);
			}
			this.map.addLayer(this.circleMarkerCluster);
		} else if (shape === 'pin' && this.pinMarkers) {
			this.fitDataToMapBoundaries(this.pinMarkers);
			this.map.addLayer(this.pinMarkers);
		}
	};

	private setMarkerColor = (type: string): string => {
		switch (type) {
			case 'unmapped': return MARKER_COLOR['red'];
			case 'not_verified': return MARKER_COLOR['purple'];
			case 'verified': return MARKER_COLOR['green'];
			case 'auto_verified': return MARKER_COLOR['yellow'];
			case 'no_recommendation': return MARKER_COLOR['blue'];
			default: return MARKER_COLOR['black'];
		}
	};

	private createCircleMarkerContent = (params: object): string => {
		return (
			`<div class="text-capitalize">
				<div>
                    <strong class="text-capitalize">${this.keys['title']}: </strong>${params[this.keys['name']]}
                </div>
                <div><strong>Pin: </strong>${params['loc_pin']}</div>
				${this.handleLoadRendering(params[this.keys['load']], 'Average Load')}
                <div>
                    <strong>Current Facility: </strong>${this.readFacilityName(params['existing_facility_id'])}
                </div>
                ${this.handleDistanceRendering(params['exst_dis'], 'Current distance')}
                <div>
                    <strong>Recomm. Facility: </strong>${this.readFacilityName(params['recommended_facility_id'])}
                </div>
				${this.handleDistanceRendering(params['rec_dis'], 'Recomm. Distance')}
				</div>`
		);
	};

	public addPolygon = (params: Array<L.LatLng>, color: string, title: string): void => {
		const polygon = L.polygon(
			[params],
			{
				fillColor: color,
				fillOpacity: .2,
				fill: true,
				stroke: true,
				color: color,
				weight: 0.6
			}
		);

		this.polygonControl[title] = polygon;
		this.polygons.addLayer(polygon);
		this.map.addLayer(this.polygons);
		this.fitDataToMapBoundaries(this.polygons);
	};

	public addMultiLineString = (geoJson, title: string): void => {
		const multiLineString = L.geoJSON(
			geoJson, {
				style: () => {
					return {
						color: '#000'
					};
				}
			}
		);

		this.polygonControl[title] = multiLineString;
		this.larekha.addLayer(multiLineString);
		this.map.addLayer(this.larekha);
	};

	private fitDataToMapBoundaries = (mapEntity): void => {
		if (mapEntity) {
			setTimeout(() => {
				const bounds = mapEntity.getBounds();
				this.map.fitBounds(bounds);
			}, 50);
		}
	};

	private readFacilityName = (code: string): string => this.facilityNameService.readName(code);

	private toggleLegend = (): boolean => this.showLegend = !this.showLegend;

	public addControlLayer = (): void => {
		setTimeout(() => {
			const pc = Object.entries(this.polygonControl);

			if (this.controlLayer) {
				this.controlLayer.remove(this.map);
			}

			if (pc && pc.length ) {
				this.controlLayer = L.control.layers(
					null,
					{...this.polygonControl},
					{position: 'topright'}
				).addTo(this.map);
			}
		});
	};

	private handleDistanceRendering = (distance: string, title: string): string => {
		if (!isNullOrUndefined(distance) && parseFloat(distance) >= 0) {
			return `<div><strong>${title}: </strong>${distance} kms</div>`;
		} else {
			return '';
		}
	};

	private handleLoadRendering = (load: string, title: string): string => {
		if (!isNullOrUndefined(load) || parseFloat(load) >= 0 ) {
			return `<div><strong>${title}: </strong>${load} /day</div>`;
		} else {
			return '';
		}
	};

	public setZoom = (locality:any,zoomLevel:number)=>{
		this.map.setView([locality.lat,locality.lng],zoomLevel);
	}
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CITY_STATS } from '../apiEndPoints.constants';
import { APIService } from './api.service';
import {DlvHttpService} from 'dlv-ng-auth';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class CityStatsService {
	constructor(
		private dlvHttp: DlvHttpService,
		private http: HttpClient,
		private apiService: APIService
	) {}

	public getCitiesStats = (): Observable<any> => {
		const endPointBase = this.apiService.getApiPath()['BASE'];
		const queryParams = this.apiService.getApiPath()['QUERY_PARAMS'];
		const url = `${environment.BASE_URI}${endPointBase}${CITY_STATS}${queryParams}`;

		return (
			this.dlvHttp
				.httpRequest({method: 'get', url})
				.pipe(
					map(response => response),
					catchError(error => error)
				)
		);
	};
}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

import {RemapService} from '../services/remap.service';
import {LoaderService} from '../../shared/components/loader/loader.service';
import {FacilityService} from '../../facility/services/facility.service';
import {CityStatsService, CommonService, GoogleAnalyticsService} from '../../shared/services';
import { RemapConstant } from '../constant/remap.constant';
import { UserRoleService } from '../../shared/services/userRole.service';
import {FacilityNameService} from '../../shared/services/facilityName.service';

@Component({
	selector: 'app-remap',
	templateUrl: './remap.component.html',
	styleUrls: ['./remap.component.scss']
})
export class RemapComponent implements OnInit {
	private jobHistoryList: Array<object> = [];
	private facilitySearchQuery: string;
	private selectedFacility: string;
	private nearestCitiesList: Array<object> = [];
	private nearestCitiesSelected: Array<object> = [];
	private citiesList: Array<object> = [];
	private selectedCity: number;
	private citySearchQuery: string;
	private jobData: object = {
		'status': null,
		'time': null,
		'error': null
	};
	private readonly jobReasonList: Array<object> = [];
	private selectedJobReason: string;
	private cityTabPermission: Array<string> = [];
	private facilityTabPermission: Array<string> = [];
	private isNoPermission: boolean;
	private refreshPage: boolean;
	private activeTab: string;
	private pageConst: object = RemapConstant;
	disablePage: boolean = false;

	constructor(
		private remapService: RemapService,
		private facilityService: FacilityService,
		private citiesStatsService: CityStatsService,
		private loader: LoaderService,
		private toaster: ToastrService,
		private actRoute: ActivatedRoute,
		private userPerm: UserRoleService,
		private commonService: CommonService,
		private facilityNameService: FacilityNameService,
		private gaService: GoogleAnalyticsService,
		private router: Router
	) {
		this.activeTab = '2';
		this.isNoPermission = false;
		this.refreshPage = false;
		this.jobReasonList = this.pageConst['JOB_REQUEST_REASONS'];
	}

	public ngOnInit(): void {
		this.actRoute.params.subscribe(params => {
			if (params['product_type'] && params['service_type'] && params['flow_type']) {
				this.commonService.selectedApp = params;
				const CONST_KEY = `${params['product_type']}_${params['service_type']}_${params['flow_type']}`;
				if(params['product_type'] === 'b2b' && params['service_type']==='lastmile' && params['country_code']==='in' && params['flow_type']==='fwd'){
					this.disablePage = true;
					return;
				}else {
					this.checkPagePermission(CONST_KEY.toUpperCase());
					this.disablePage = false;
				}
			}
		});
	}

	private setActiveTab = (): string => {
		let activeTab = '2';
		if (this.userPerm.checkPagePermission(this.cityTabPermission)
			&& this.userPerm.checkPagePermission(this.facilityTabPermission)) {
			activeTab = '2';
		} else if (this.userPerm.checkPagePermission(this.cityTabPermission)) {
			activeTab = '1';
		} else if (this.userPerm.checkPagePermission(this.facilityTabPermission)) {
			activeTab = '2';
		} else {
			activeTab = '';
		}

		return activeTab;
	};

	private checkPagePermission = (constKey: string): void => {
		this.refreshPage = true;
		this.isNoPermission = true;
		this.cityTabPermission = this.pageConst[`CITY_${constKey}`] ? this.pageConst[`CITY_${constKey}`] : [];
		this.facilityTabPermission = this.pageConst[`FACILITY_${constKey}`]
			? this.pageConst[`FACILITY_${constKey}`]
			: [];
		const PAGE_PERMISSION = [...(new Set(this.cityTabPermission.concat(this.facilityTabPermission)))];

		if (PAGE_PERMISSION.length && this.userPerm.checkPagePermission(PAGE_PERMISSION)) {
			setTimeout(() => {
				this.isNoPermission = false;
				this.activeTab = this.setActiveTab();
				this.fetchJobsHistory();
				this.fetchCitiesList();
				this.refreshPage = false;
				this.selectedJobReason = this.jobReasonList[0]['value'];
			}, 0);
		} else {
			this.isNoPermission = true;
			this.refreshPage = false;
			this.loader.stop();
		}
	};

	private fetchJobsHistory = (event?: object): void => {
		this.loader.start();

		if (event) {
			this.gaService.trackEvent(
				'click',
				'button',
				'job history refresh',
				0
			);
		}

		this.remapService
			.getJobHistory()
			.subscribe((job: Array<object>) => {
				this.jobHistoryList = job;
				this.loader.stop();
			}, () => {
				this.loader.stop();
				this.router.navigate(['/error']);
			});
	};

	private fetchCitiesList = (): void => {
		this.citiesStatsService
			.getCitiesStats()
			.subscribe((citiesList: Array<object>) => {
				this.citiesList = citiesList;
				this.loader.stop();
			}, () => {
				this.loader.stop();
				this.router.navigate(['/error']);
			});
	};

	private chooseCity = (params: object): void => {
		this.citySearchQuery = params['name'];
		this.selectedCity = params['city_id'];

		this.fetchJobStatus(this.selectedCity);

		const element = document.getElementsByClassName('dropdown-menu');
		element[0].setAttribute('style', 'display: none');
	};

	/**
     * To fetch job status for the selected city
     * @param params       object       selected city data
     */
	private fetchJobStatus = (params: number): void => {
		this.loader.start();

		this.remapService
			.getJobStatus(params)
			.subscribe((response: Array<object>) => {
					this.jobData = {...response};
					this.loader.stop();
				},
				error => {
					this.loader.stop();
					this.router.navigate(['/error']);
				});
	};

	private chooseNearestCities = (event: any, params: object): Array<object> => {
		if (event.target.checked) {
			this.nearestCitiesSelected.push(params);
		} else {
			if (this.nearestCitiesSelected.length) {
				this.nearestCitiesSelected = this.nearestCitiesSelected
					.filter(facility => facility['id'] !== params['id']);
			}
		}

		return this.nearestCitiesSelected;
	};

	private setJobParams = (params: Array<object>, entity: string): object => {
		if (entity === 'facility') {
			return {
				'cities': params.map(city => city['id']),
				'facility': this.selectedFacility,
				'reason': this.selectedJobReason
			};
		} else {
			return {
				'city': params
			};
		}
	};

	/**
     * To run job for city or facility'nearby cities
     * @param params        object      see comment of setJobParams
     * @param entity        string      i.e. city or facility
     */
	private requestJob = (params: Array<object>, entity?: string): void => {
		this.loader.start();

		this.remapService
			.postJobRequest(this.setJobParams(params, entity))
			.subscribe(response => {
				if (entity === 'city') {
					this.fetchJobStatus(this.selectedCity);
				} else if (entity === 'facility') {
					this.nearestCitiesList = [];
				}

				this.fetchJobsHistory();
				this.loader.stop();
				this.toaster.success(
					response['success'],
					'success',
					{timeOut: 3000}
				);
			}, error => {
				this.loader.stop();
				this.toaster.error(
					JSON.stringify(error),
					'error',
					{timeOut: 5000}
				);
			});
	};

	/**
     * To fetch nearest cities of selected facility
     * And creates list containing nearest cities (5 in current scenario)
     * @param params    object          selected facility'data
     */
	private fetchNearestCitiesOfFacility = (params: object): void => {
		if (params.hasOwnProperty('id')) {
			this.loader.start();

			this.selectedFacility = params['id'];

			this.facilityService
				.getNearestCitiesOfFacility(params)
				.subscribe(response => {
						this.nearestCitiesList = response['result'];
						this.loader.stop();
					}, () => {
						this.loader.stop();
						this.router.navigate(['/error']);
						this.nearestCitiesList = [];
					});
		}
	};

	private readFacilityName = (facilityID: string): string => this.facilityNameService.readName(facilityID);

	private handleActiveTab = (event: object): void => {
		this.activeTab = event['nextId'];

		this.gaService.trackEvent(
			'change',
			'tabs',
			`${this.activeTab === '2' ? 'facility' : 'city'} tab click`,
			0
		);

		if (this.activeTab === '1') {
			this.selectedFacility = undefined;
			this.nearestCitiesList = [];
			this.selectedJobReason = this.jobReasonList[0]['value'];
		} else {
			this.selectedCity = undefined;
			this.citySearchQuery = undefined;
		}
	};
}

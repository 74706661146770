export const RemapConstant: object = {
	'JOB_REQUEST_REASONS': [
		{
			id: 0,
			value: '-- Select Reason --'
		},
		{
			id: 1,
			value: 'Facility Relocation'
		},
		{
			id: 2,
			value: 'Facility Split'
		},
		{
			id: 3,
			value: 'Facility Shut down'
		},
		{
			id: 4,
			value: 'New Facility'
		}
	],

	'CITY_B2C_LASTMILE_FWD': ['BtoC_lm_fwd_can_run_city_job'],
	'CITY_B2C_LASTMILE_RET': ['BtoC_lm_ret_can_run_city_job'],
	'CITY_B2C_LASTMILE_RVP': ['BtoC_lm_rvp_can_run_city_job'],
	'CITY_B2C_FIRSTMILE_FWD': ['BtoC_fm_fwd_can_run_city_job'],
	'CITY_B2C_FIRSTMILE_RET': ['BtoC_fm_ret_can_run_city_job'],
	'CITY_B2C_FIRSTMILE_RVP': ['BtoC_fm_rvp_can_run_city_job'],
	'CITY_B2B_FIRSTMILE_FWD': ['BtoB_fm_fwd_can_run_city_job'],
	'CITY_B2B_FIRSTMILE_RET': ['BtoB_fm_ret_can_run_city_job'],
	'CITY_B2B_FIRSTMILE_RVP': ['BtoB_fm_rvp_can_run_city_job'],
	'CITY_B2B_LASTMILE_FWD': ['BtoB_lm_fwd_can_run_city_job'],
	'CITY_B2B_LASTMILE_RET': ['BtoB_lm_ret_can_run_city_job'],
	'CITY_B2B_LASTMILE_RVP': ['BtoB_lm_rvp_can_run_city_job'],

	'FACILITY_B2C_LASTMILE_FWD': ['BtoC_lm_fwd_can_run_facility_job'],
	'FACILITY_B2C_LASTMILE_RET': ['BtoC_lm_ret_can_run_facility_job'],
	'FACILITY_B2C_LASTMILE_RVP': ['BtoC_lm_rvp_can_run_facility_job'],
	'FACILITY_B2C_FIRSTMILE_FWD': ['BtoC_fm_fwd_can_run_facility_job'],
	'FACILITY_B2C_FIRSTMILE_RET': ['BtoC_fm_ret_can_run_facility_job'],
	'FACILITY_B2C_FIRSTMILE_RVP': ['BtoC_fm_rvp_can_run_facility_job'],
	'FACILITY_B2B_FIRSTMILE_FWD': ['BtoB_fm_fwd_can_run_facility_job'],
	'FACILITY_B2B_FIRSTMILE_RET': ['BtoB_fm_ret_can_run_facility_job'],
	'FACILITY_B2B_FIRSTMILE_RVP': ['BtoB_fm_rvp_can_run_facility_job'],
	'FACILITY_B2B_LASTMILE_FWD': ['BtoB_lm_fwd_can_run_facility_job'],
	'FACILITY_B2B_LASTMILE_RET': ['BtoB_lm_ret_can_run_facility_job'],
	'FACILITY_B2B_LASTMILE_RVP': ['BtoB_lm_rvp_can_run_facility_job']
};

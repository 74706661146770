export const CommonConstant: object = {
	'FIRSTMILE_FLOW': [
		{
			value: 'fwd',
			name: 'Forward'
		},

		{
			value: 'ret',
			name: 'Return'
		},
		{
			value: 'rvp',
			name: 'Reverse'
		}
	],

	'LASTMILE_FLOW': [
		{
			value: 'fwd',
			name: 'Forward'
		}
	]
};

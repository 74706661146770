export const MARKER: object = {
	'red': './assets/images/map/red-pin.png',
	'green': './assets/images/map/green-pin.png',
	'blue': './assets/images/map/blue-pin.png',
	'purple': './assets/images/map/purple-pin.png',
	'yellow': './assets/images/map/yellow-pin.png',
	'black': './assets/images/map/black-pin.png'
};

export const MARKER_COLOR: object = {
	'red': '#d80027',
	'green': '#91dc5a',
	'blue': '#006df0',
	'purple': '#933ec5',
	'yellow': '#ffda44',
	'black': '#000000',
};

export const MARKER_COLOR_MAPPING: object = {
	'unmapped': MARKER_COLOR['red'],
	'not_verified': MARKER_COLOR['purple'],
	'verified': MARKER_COLOR['green'],
	'auto_verified': MARKER_COLOR['yellow'],
	'auto_verified_msf': MARKER_COLOR['black'],
	'no_recommendation': MARKER_COLOR['blue']
};

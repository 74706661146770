import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'numSearch'})
export class StaticNumDataFilterPipe implements PipeTransform {
	public transform(data: Array<object>, query: number, key: string): Array<object> {
		let queryString;
		queryString = query ? query.toString() : '';

		return data.filter((entry) => (entry[key]).toString().toLowerCase().includes(queryString));
	}
}

import {
	Component,
	EventEmitter,
	Input,
	Output,
	OnInit,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

import {AutoCompleteService} from '../../services/autocomplete.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss']
})
export class AutoCompleteComponent implements OnInit, OnChanges {
	private noResult: boolean;
	private isSpin: boolean;
	private isSelect: boolean;
	private default_value: object;

	@Input() private isDisabled: boolean;
	@Input() private placeHolder: string;
	@Input() private autoCompleteType: string;
	@Input() private defaultValue: string;
	@Input() private fields?: string;
	@Input() private isFocus: boolean;
	@Input() private isClear?: boolean;

	@Output() private queryData: EventEmitter<any> = new EventEmitter<any>();
	@Output() private selectedItem: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private autoComplete: AutoCompleteService,
		private toaster: ToastrService
	) {
		this.noResult = false;
		this.isSpin = false;
		this.isSelect = false;
	}

	public ngOnInit(): void {
		this.default_value = { value: this.defaultValue || '' };
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (
			changes['isClear']
			&& changes['isClear']['previousValue'] !== undefined
			&& changes['isClear']['currentValue']
		) {
			this.default_value = { value: '' };
			this.selectItem();
		}

		if (
			changes['defaultValue']
			&& changes['defaultValue']['currentValue']
		) {
			this.default_value = {value : changes['defaultValue']['currentValue']};
			this.selectItem({item: this.default_value});
		}
	}

	private isChange = (data: object): void => {
		if (!this.isSelect || !data) {
			this.selectedItem.emit({});
		}

		if (data) {
			this.queryData.emit(data);
		} else {
			this.queryData.emit(false);
		}
	};

	/**
     * to call the autocomplete api from the service
     * @param {Observable<string>} text$          input string (min 3 characters)
     * @returns {Observable<any>}
     */
	private fetchResults = (text$: Observable<string>): Observable<Array<object>> => {
		return (
			text$
				.debounceTime(500)
				.distinctUntilChanged()
				.switchMap(query => {
					if (query.length > 2) {
						this.isSpin = true;
						return (
							this.autoComplete
								.getSearchResult(query, this.autoCompleteType, this.fields)
								.do((response) => {
									this.isSpin = false;

									if (response && response['length']) {
										this.noResult = false;

										return response;
									} else {
										this.noResult = true;

										return [];
									}
								})
								.catch((error) => {
									this.noResult = true;
									this.isSpin = false;
									this.toaster.error(JSON.stringify(error), 'ERROR', {timeOut: 2000});

									return [];
								})
						);
					} else {
						this.noResult = false;
						this.isSpin = false;

						return [];
					}
				})
		);
	};

	private outputFormat = (result: object): string => result['value'] || '';

	private inputFormat = (result: object): string => result['value'] || '';

	private selectItem = ($event?): void => {
		this.isSelect = true;
		this.isSpin = false;

		$event ? this.selectedItem.emit($event.item) : this.selectedItem.emit({});
	};
}

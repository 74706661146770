import { Injectable } from '@angular/core';
import {
	CanActivate,
	CanLoad,
	Router,
	Route,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	ActivatedRoute,
	Params
} from '@angular/router';

import { AuthService } from 'dlv-ng-auth';
import {CommonService} from '../../shared/services';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {
	private productType: Array<string> = ['b2b', 'b2c'];
	private serviceType: Array<string> = ['lastmile', 'firstmile'];

	constructor(
		private auth: AuthService,
		private router: Router,
		private actRoute: ActivatedRoute,
		private commonService: CommonService
	) {}

	canLoad = (route: Route): boolean => this.checkLogin(`/${route.path}`);

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.checkParam(route.params);
		return this.checkLogin(state.url);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.checkParam(route.params);
		return this.checkLogin(state.url);
	}

	private checkLogin = (url: string): boolean => {
		const isLoggedIn = this.auth.isAuthenticated();
		if (url === '/login' && isLoggedIn) {
			this.navigateTo('/select-app');
			return true;
		} else if (url === '/login' && !isLoggedIn) {
			return true;
		} else if (isLoggedIn) {
			return true;
		} else {
			this.navigateTo('login');
			return false;
		}
	};

	private navigateTo = (route: string): void => {
		this.router.navigate([`/${route}`], {replaceUrl: true});
	};

	private checkParam = (params: Params): boolean => {
		if (params &&
			Object.keys(params).length &&
			this.productType.includes(params['product_type']) &&
			this.serviceType.includes(params['service_type'])
		) {
			this.commonService.selectedApp = params;

		} else {
			return false;
		}
	};
}
